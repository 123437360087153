import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.canerture.androidhub.pages.index.Index() }
        ctx.router.register("/admin/all-posts") {
                com.canerture.androidhub.pages.adminallposts.AdminAllPosts() }
        ctx.router.register("/admin/create-post") {
                com.canerture.androidhub.pages.admincreatepost.AdminCreatePost() }
        ctx.router.register("/admin/my-posts") {
                com.canerture.androidhub.pages.adminmyposts.MyPostsScreen() }
        ctx.router.register("/author") { com.canerture.androidhub.pages.author.Author() }
        ctx.router.register("/category") { com.canerture.androidhub.pages.category.Category() }
        ctx.router.register("/login") { com.canerture.androidhub.pages.login.LoginScreen() }
        ctx.router.register("/posts") { com.canerture.androidhub.pages.post.PostPageLayout() }
        ctx.router.register("/search") { com.canerture.androidhub.pages.search.Search() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ShadowedBlueVariant)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ShadowedGreenVariant)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ShadowedRedVariant)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ShadowedGrayVariant)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.DropdownStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.DropdownContentStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.InputCheckBoxStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ContainerStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.SuccessStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.ErrorStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.LoaderStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.NavbarStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.NavItemStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.components.layouts.PageContentStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.components.sections.FooterStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.components.sections.NavigationItemStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.pages.admincreatepost.EditorKeyStyle)
        ctx.theme.registerComponentStyle(com.canerture.androidhub.pages.login.LoginInputStyle)
        ctx.stylesheet.registerKeyframes(com.canerture.androidhub.Spin)
        com.canerture.androidhub.initSiteStyles(ctx)
        com.canerture.androidhub.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "."))
    renderComposable(rootElementId = "root") {
        com.canerture.androidhub.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
